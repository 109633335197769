
import { defineComponent } from "vue";
import Card from "primevue/card";
import Badge from "primevue/badge";
import Tooltip from "primevue/tooltip";
import Utils from "@/utility/utils";
import { mapGetters } from "vuex";
export default defineComponent({
  name: "StatCard",
  components: {
    Card,
    Badge,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    selected: Boolean,
    title: String,
    subtitle: String,
    getter: String,
    setter: String,
    amountArray: {
      default: (): Array<number> => [],
    },
  },
  data() {
    return {
      higherAmountReference: 1,
      lastPercentageDiff: 0,
      lastAmountDiff: 0,
      dataArray: [] as any,
    };
  },
  computed: {
    ...mapGetters({
      getCustomer: "customer/getCust",
    }),
    graphAmountArray(): any {
      if (this.dataArray) {
        if (this.dataArray.length != 0) {
          return this.dataArray.map((month: any) => {
            return {
              amount: month.amount,
              count: month.count,
              month: month.month,
              year: month.year,
              percentageFromMax:
                (parseFloat(month.amount) * 100) / this.higherAmountReference,
            };
          });
        }
      }

      return 0;
    },
    LastFormattedAmount(): string {
      if (this.dataArray) {
        if (this.dataArray[11]) {
          let amount = this.dataArray[11].amount;
          if (amount.includes(".")) {
            amount = this.formatPrice(amount);
          }
          return amount;
        }
      }
      return "";
    },
    currentMonth(): string {
      const date = new Date();
      return (date.getMonth() + 1).toString();
    },
    currentYear(): string {
      const date = new Date();
      return date.getFullYear().toString();
    },
    formattedDateRange(): string {
      if (this.dataArray) {
        if (this.dataArray[11]) {
          let endDate = new Date(
            parseInt(this.dataArray[11].year),
            parseInt(this.dataArray[11].month),
            0,
          );
          const endMonth = new Intl.DateTimeFormat("en-US", {
            month: "long",
          }).format(endDate);
          let endDay = endDate.getDate();
          if (
            endDate.getMonth() + 1 === parseInt(this.currentMonth) &&
            endDate.getFullYear() === parseInt(this.currentYear)
          ) {
            endDay = new Date().getDate();
          }
          const endYear = endDate.getFullYear();
          let startDate = endDate;
          startDate.setMonth(endDate.getMonth() - 11);
          const startMonth = new Intl.DateTimeFormat("en-US", {
            month: "long",
          }).format(startDate);
          const startDay = 1;
          const startYear = endDate.getFullYear();
          return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
        }
      }
      return "";
    },
  },
  watch: {
    amountArray() {
      this.dataArray = [];
      this.$nextTick(() => {
        this.dataArray = JSON.parse(JSON.stringify(this.amountArray));
        this.getHigherAmountReference();
        this.getLastPercentageDiff();
        this.$emit("done-loading", true);
      });
    },
  },
  methods: {
    getBadgeValue(lastAmountDiff: number) {
      let price = this.lastPercentageDiff > 0 ? "+" : "";
      price += this.formatPrice(lastAmountDiff);
      return this.title !== "Tickets" ? price : price.replace("$", "") + "";
    },
    getTooltip(index: number) {
      const currentHover = this.graphAmountArray[index];

      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        new Date().setMonth(parseInt(currentHover.month) - 1),
      );
      const year = currentHover.year;
      let amount = currentHover.amount;

      if (amount.includes(".")) {
        amount = this.formatPrice(amount);
      }

      const count = currentHover.count;
      let sentence = month + " " + year + "\n" + amount;
      if (this.title === "Tickets") {
        sentence += " " + this.title;
      } else {
        sentence += "\n" + count + " " + this.title;
      }
      return sentence;
    },
    getHigherAmountReference() {
      let amountArray = [] as any;
      if (this.dataArray) {
        this.dataArray.forEach((month: any) => {
          amountArray.push(month.amount);
        });
      }
      this.higherAmountReference = Math.max(...amountArray);
    },
    getLastPercentageDiff() {
      if (
        this.dataArray &&
        this.dataArray[this.dataArray.length - 1] &&
        this.dataArray[this.dataArray.length - 2]
      ) {
        this.lastPercentageDiff =
          Math.round(
            (parseFloat(this.dataArray[this.dataArray.length - 1].amount) *
              100) /
              parseFloat(this.dataArray[this.dataArray.length - 2].amount) -
              100,
          ) || 0;
        this.lastAmountDiff =
          parseFloat(this.dataArray[this.dataArray.length - 1].amount) -
          parseFloat(this.dataArray[this.dataArray.length - 2].amount);
      }
    },
    formatPrice(amount: number): string {
      return Utils.formatPrice(amount);
    },
  },
  async created() {
    this.dataArray = JSON.parse(JSON.stringify(this.amountArray));
    this.getHigherAmountReference();
    this.getLastPercentageDiff();
  },
});
