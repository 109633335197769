
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import CustomerInquiryForm from "@/components/CustomerInquiryForm.vue";
@Options({
  components: {
    CustomerInquiryForm,
  },

  computed: {
    ...mapState(["customerInquiry"]),
  },
})
export default class CustomerInquiry extends Vue {}
